import React from "react";
import { Box, Typography, Stack, Button } from "@mui/material";
import Navbar from "../components/layout/Navbar";
import { ArrowBack, ArrowOutward } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const PrivateDataDex = () => {
  const navigate = useNavigate(); // Initialize useNavigate

  const handleNavigationHome = () => {
    navigate("/"); // Navigate to the "/" route
  };
  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "100dvh", // Full height of viewport
        overflow: "auto",
        backgroundColor: "black", // Set background color to black for the whole page
      }}
    >
      {/* Navbar */}
      <Navbar
        backgroundColor={"black"}
        sx={{
          position: "sticky",
          top: 0,
          left: 0,
          width: "100%",
          boxShadow: "none",
          zIndex: 2, // Ensure the navbar stays above the particles
        }}
      />

      <Stack>
        {/* Top section where particles should be visible */}
        <Box
          sx={{
            height: "40vh",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            alignItems: "center",
            borderBottom: "1px solid",
            borderColor: "#272727",
            backgroundColor: "transparent", // Transparent so particles show
            zIndex: 1,
            "@media print": {
              borderBottom: "none", // Remove borders for print
              backgroundColor: "transparent !important", // Ensure no background color for printing
              height: "auto",
            },
          }}
        >
          {/* Particles Background Scoped to 40vh Box */}
          <Box
            sx={{
              position: "absolute",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
              zIndex: -1, // Particles stay behind the content
              "@media print": {
                display: "none !important", // Hide particles background when printing
              },
            }}
          >
            <Box
              component="img"
              src="/n-dimensional-image.png"
              alt="dex image"
              sx={{
                width: "100%",
                height: { xs: "100%", sm: "100%", md: "100%" }, // Auto height for mobile, fixed height for desktop
                objectFit: "contain", // Crop the image to fill the height
                objectPosition: "center", // Center the image while cropping
                borderRadius: "8px",
              }}
            />
          </Box>

          <Stack
            spacing={1}
            sx={{
              width: "100%",
              maxWidth: "776px",
              alignItems: "start",
              paddingLeft: "24px",
              paddingRight: "24px",
              paddingBottom: { xs: "24px", sm: "24px", md: "24px", lg: "24px" },
              zIndex: 2, // Ensure text is above the particles
              "@media print": {
                height: "auto",
              },
            }}
          >
            <Button
              onClick={handleNavigationHome}
              variant="text"
              startIcon={
                <ArrowBack
                  sx={{
                    fontSize: "16px",
                    "&:hover": {
                      color: "#FFF1D5", // Text color when hovered
                      textShadow:
                        "0 0 1px #FFF1D5, 0 0 2px #FFF1D5, 0 0 8px #FFF1D5", // Glowing effect
                    },
                  }}
                />
              }
              sx={{
                fontFamily: "Electrolize, Arial, sans-serif",
                color: "#DCDCDC",
                fontSize: { xs: "16px", sm: "20px", md: "20px", lg: "20px" },
                fontWeight: "500",
                "@media (hover: hover)": {
                  "&:hover": {
                    color: "#FFF1D5", // Text color when hovered
                    textShadow:
                      "0 0 1px #FFF1D5, 0 0 2px #FFF1D5, 0 0 8px #FFF1D5", // Glowing effect
                  },
                },
              }}
            >
              Home
            </Button>
            <Typography
              gutterBottom
              color="white"
              sx={{
                typography: { xs: "h5", sm: "h4", md: "h4", lg: "h4" },
              }}
            >
              Private Data DEX
            </Typography>
            <Typography
              sx={{
                fontFamily: "Electrolize, Arial, sans-serif",
                color: "#DCDCDC",
                fontSize: { xs: "16px", sm: "20px", md: "20px", lg: "20px" },
                fontWeight: "500",
                "@media print": {
                  fontSize: "14px !important",
                },
              }}
            >
              3 min read
            </Typography>
          </Stack>
        </Box>

        {/* Main content section with solid black background */}
        <Box>
          <Stack
            spacing={4}
            sx={{
              maxWidth: "776px",
              margin: "0 auto",
              padding: 3,
              paddingY: { xs: "24px", sm: "24px", md: "36px", lg: "36px" },
              backgroundColor: "black", // Solid black for the main content area
              zIndex: 1, // Content is above particles, but no particles here
            }}
          >
            {/* <Box
              display="flex"
              justifyContent="center" // Center horizontally
              alignItems="center" // Center vertically
            >
              
            </Box> */}

            <Typography
              color="white"
              sx={{
                typography: {
                  xs: "body2",
                  sm: "body1",
                  md: "body1",
                  lg: "body1",
                },
              }}
            >
              A looming question about the impending AI revolution is whether
              the market – and with it a tremendous amount of influence – will
              be won by centralized and unverifiable black box models developed
              by a small number of self-interested companies and agenda-driven
              governments that have access to all the data, or by verifiable
              ones developed in an open, competitive marketplace and trained on
              user-owned data. <br /> <br />
              An efficient and private exchange for data is an essential layer
              of a tech stack that allows open and verifiable models to compete.
              Nebula is building a private DEX that allows individuals to
              maximize monetization of their data by lending it out for model
              training.
            </Typography>
            {/* Massive Unaddressed Market H2 */}
            <Stack spacing={1}>
              <Typography
                color="white"
                sx={{
                  fontFamily: "Electrolize, Arial, sans-serif",
                  fontSize: { xs: "20px", sm: "28px", md: "28px", lg: "28px" },
                  fontWeight: "600",
                }}
              >
                Massive Unaddressed Market
              </Typography>

              <Typography
                color="white"
                sx={{
                  typography: {
                    xs: "body2",
                    sm: "body1",
                    md: "body1",
                    lg: "body1",
                  },
                }}
              >
                The largest companies in the world today are worth trillions of
                dollars largely because of their data oligopoly. Despite there
                not being an open market for it, data is one of the most
                valuable and fastest-growing asset classes in the world. <br />{" "}
                <br />
                Allowing smaller developers and individuals to participate in
                the data market will dramatically increase the competition
                between models, control users have over the algorithms they
                interact with and size of the market. <br /> <br /> Two of the
                key innovations of Nebula exchange originally designed with
                financial assets in mind – privacy and multi-dimensionality –
                are also necessary for facilitating optimal data exchange.
              </Typography>
            </Stack>
            {/* privacy h2 */}
            <Stack spacing={1}>
              <Typography
                color="white"
                sx={{
                  fontFamily: "Electrolize, Arial, sans-serif",
                  fontSize: { xs: "20px", sm: "28px", md: "28px", lg: "28px" },
                  fontWeight: "600",
                }}
              >
                Privacy
              </Typography>

              <Typography
                color="white"
                sx={{
                  typography: {
                    xs: "body2",
                    sm: "body1",
                    md: "body1",
                    lg: "body1",
                  },
                }}
              >
                Verified computation over encrypted data is a key ingredient in
                solving the toxic flow and MEV problems that have plagued DEXs
                to date. It is also necessary to enable users to fully monetize
                ownership of their data. <br /> <br /> Once data is shared, its
                value diminishes significantly because it is nearly impossible
                to verify whether the receiving party deletes or forgets the
                information after viewing it. FHE will enable models to train on
                data and respond to queries without having to read the
                unencrypted form of the training or input data, such that users
                need not disclose their information. <br /> <br /> Instead of
                selling their data, users will be able to lease it out.
              </Typography>
            </Stack>
            {/* n dimensionality - h2 */}
            <Stack spacing={1}>
              <Typography
                color="white"
                sx={{
                  fontFamily: "Electrolize, Arial, sans-serif",
                  fontSize: { xs: "20px", sm: "28px", md: "28px", lg: "28px" },
                  fontWeight: "600",
                }}
              >
                n-Dimensionality
              </Typography>

              <Typography
                color="white"
                sx={{
                  typography: {
                    xs: "body2",
                    sm: "body1",
                    md: "body1",
                    lg: "body1",
                  },
                }}
              >
                Data is theoretically infinitely dimensional. Because AI will
                glean new insight from information that appears entirely
                unrelated to us now (e.g., eye color and diabetes, sleep
                patterns and IQ), infrastructure that allows data to be borrowed
                as a set linked by its common source rather than accessed
                atomically will unlock tremendous value. <br /> <br />
                Matching people creating a model with those who own the data
                best suited to train it and structuring a trustless and
                verifiable lease between them is an ideal use case for Nebula’s
                n-dimensionality. Other exchanges built for uni-dimensional
                assets (<i>e.g.</i>, Uniswap) will have to rearchitect their
                entire stack to support n-dimensionality.
              </Typography>
            </Stack>
            {/* Key Rotation H3 */}
            <Stack spacing={1}>
              <Typography
                color="white"
                sx={{
                  fontFamily: "Electrolize, Arial, sans-serif",
                  fontSize: { xs: "20px", sm: "28px", md: "28px", lg: "28px" },
                  fontWeight: "600",
                }}
              >
                Optimal Matching
              </Typography>

              <Typography
                color="white"
                sx={{
                  typography: {
                    xs: "body2",
                    sm: "body1",
                    md: "body1",
                    lg: "body1",
                  },
                }}
              >
                The optimal market for data will enable efficient matching
                between: <br />
                <br /> (1) data owners who list their data as a single set with
                custom settings on the cost to borrow any particular subset, and{" "}
                <br />
                <br />
                (2) model trainers who borrow the exact dataset they want based
                on the unique traits they want to use for a particular model.{" "}
                <br /> <br />
                Nebula’s n-dimensional token, order and clearing schemes are
                able to perform exactly this type of highly customized matching
                in a verifiable fashion. Users will get proof that their data
                was used in accordance with their permissions and they were
                properly compensated for its use, and model trainers will be
                able to verify the quality of the data their model was trained
                on to users. <br />
                <br />
                Data’s digital nature and global scale make it an ideal catalyst
                for the transition to an AI-powered economy that empowers
                individuals with data sovereignty. Nebula’s private
                n-dimensional exchange will facilitate this transition. <br />
                <br />
              </Typography>
            </Stack>
            {/* Action buttons */}
            {/* <Stack
              direction={{ xs: "column", sm: "row" }}
              sx={{ width: { xs: "100%", sm: "none" } }}
              spacing={2}
            >
              <Stack direction="row" spacing={2}>
                <Stack sx={{ width: { xs: "100%", sm: "auto" } }}>
                  <Button
                    onClick={handleNavigationFHE}
                    variant="contained"
                    sx={{
                      fontFamily: "Electrolize, Arial, sans-serif",
                      fontSize: {
                        xs: "16px",
                        sm: "20px",
                        md: "20px",
                        lg: "20px",
                      },
                      height: {
                        xs: "44px",
                        sm: "52px",
                        md: "52px",
                        lg: "52px",
                      },
                      color: "white",
                      backgroundColor: "#1B1B1B",
                      border: "1px solid",
                      borderColor: "#494949",
                      "&:hover": {
                        backgroundColor: "rgba(27, 27, 27, 0.6)", // Change opacity on hover
                        color: "rgba(255, 255, 255, 0.8)", // Change text color opacity on hover
                      },
                    }}
                  >
                    read about FHE
                  </Button>
                </Stack>
                <Stack sx={{ width: { xs: "100%", sm: "auto" } }}>
                  <Button
                    onClick={handleNavigationDEX}
                    variant="contained"
                    sx={{
                      fontFamily: "Electrolize, Arial, sans-serif",
                      fontSize: {
                        xs: "16px",
                        sm: "20px",
                        md: "20px",
                        lg: "20px",
                      },
                      height: {
                        xs: "44px",
                        sm: "52px",
                        md: "52px",
                        lg: "52px",
                      },
                      color: "white",
                      backgroundColor: "#1B1B1B",
                      border: "1px solid",
                      borderColor: "#494949",
                      "&:hover": {
                        backgroundColor: "rgba(27, 27, 27, 0.6)", // Change opacity on hover
                        color: "rgba(255, 255, 255, 0.8)", // Change text color opacity on hover
                      },
                    }}
                  >
                    read about DEXs
                  </Button>
                </Stack>
              </Stack>
              <Button
                onClick={handleContact}
                variant="contained"
                endIcon={<ArrowOutward />}
                sx={{
                  fontFamily: "Electrolize, Arial, sans-serif",
                  fontSize: { xs: "16px", sm: "20px", md: "20px", lg: "20px" },
                  height: { xs: "44px", sm: "52px", md: "52px", lg: "52px" },
                  color: "white",
                  backgroundColor: "black",
                  border: "1px solid",
                  borderColor: "#494949",
                  "&:hover": {
                    backgroundColor: "black",
                    color: "rgba(255, 255, 255, 0.8)", // Change text color opacity on hover
                    borderColor: "rgba(73, 73, 73, 0.8)", // Change border color opacity on hover
                  },
                }}
              >
                contact us
              </Button>
            </Stack> */}
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};

export default PrivateDataDex;
