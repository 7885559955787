import React from "react";
import { Box, Typography, Stack, Button, Divider } from "@mui/material";
import Navbar from "../components/layout/Navbar";
import { ArrowBack, ArrowOutward } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import Spline from "@splinetool/react-spline";
import "katex/dist/katex.min.css";

const TeamBio = () => {
  const navigate = useNavigate(); // Initialize useNavigate

  const handleNavigationHome = () => {
    navigate("/"); // Navigate to the "/" route
  };

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "100dvh", // Full height of viewport
        overflow: "auto",
        backgroundColor: "black", // Set background color to black for the whole page
      }}
    >
      {/* Navbar */}
      <Navbar
        backgroundColor={"black"}
        sx={{
          position: "sticky",
          top: 0,
          left: 0,
          width: "100%",
          boxShadow: "none",
          zIndex: 2, // Ensure the navbar stays above the particles
        }}
      />

      <Stack>
        {/* Top section where particles should be visible */}
        <Box
          sx={{
            height: "40vh",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            alignItems: "center",
            borderBottom: "1px solid",
            borderColor: "#272727",
            backgroundColor: "transparent", // Transparent so particles show
            zIndex: 1,
            "@media print": {
              borderBottom: "none", // Remove borders for print
              backgroundColor: "transparent !important", // Ensure no background color for printing
              height: "auto",
            },
          }}
        >
          {/* Particles Background Scoped to 40vh Box */}
          <Box
            sx={{
              position: "absolute",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
              zIndex: -1, // Particles stay behind the content
              "@media print": {
                display: "none !important", // Hide particles background when printing
              },
            }}
          >
            <Spline
              scene="https://prod.spline.design/AR2OsVSryukNaSwZ/scene.splinecode"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
            />
          </Box>

          <Stack
            spacing={1}
            sx={{
              width: "100%",
              maxWidth: "824px",
              alignItems: "start",
              paddingLeft: "24px",
              paddingRight: "24px",
              paddingBottom: { xs: "24px", sm: "24px", md: "24px", lg: "24px" },
              zIndex: 2, // Ensure text is above the particles
              "@media print": {
                height: "auto",
              },
            }}
          >
            <Button
              onClick={handleNavigationHome}
              variant="text"
              startIcon={
                <ArrowBack
                  sx={{
                    fontSize: "16px",
                    "&:hover": {
                      color: "#FFF1D5", // Text color when hovered
                      textShadow:
                        "0 0 1px #FFF1D5, 0 0 2px #FFF1D5, 0 0 8px #FFF1D5", // Glowing effect
                    },
                  }}
                />
              }
              sx={{
                fontFamily: "Electrolize, Arial, sans-serif",
                color: "#DCDCDC",
                fontSize: { xs: "16px", sm: "20px", md: "20px", lg: "20px" },
                fontWeight: "500",
                "@media (hover: hover)": {
                  "&:hover": {
                    color: "#FFF1D5", // Text color when hovered
                    textShadow:
                      "0 0 1px #FFF1D5, 0 0 2px #FFF1D5, 0 0 8px #FFF1D5", // Glowing effect
                  },
                },
              }}
            >
              Home
            </Button>
            <Typography
              gutterBottom
              color="white"
              sx={{
                typography: { xs: "h5", sm: "h4", md: "h4", lg: "h4" },
              }}
            >
              Select Team Members
            </Typography>
          </Stack>
        </Box>

        {/* Main content section with solid black background */}
        <Box>
          <Stack
            spacing={4}
            sx={{
              maxWidth: "824px",
              margin: "0 auto",
              padding: 3,
              paddingY: { xs: "24px", sm: "24px", md: "36px", lg: "36px" },
              backgroundColor: "black", // Solid black for the main content area
              zIndex: 1, // Content is above particles, but no particles here
            }}
          >
            <Stack
              sx={{
                flexDirection: {
                  xs: "column",
                  sm: "column",
                  md: "row",
                  lg: "row",
                },
              }}
            >
              <Box
                component="img"
                sx={{
                  width: { xs: "72px", lg: "96px" },
                  height: { xs: "72px", lg: "96px" },
                  flexShrink: 0, // Prevents shrinking
                  borderRadius: 1.5,
                  border: "4px solid",
                  borderColor: "#191818",
                  marginRight: 2,
                  marginBottom: 2,
                  "@media print": {
                    border: "none !important", // Hide particles background when printing
                  },
                }}
                alt="ben"
                src="ben.png"
              />
              <Stack spacing={2}>
                <Stack spacing={0.5}>
                  <Stack direction="row" spacing={2}>
                    <Typography
                      color="white"
                      sx={{
                        typography: {
                          xs: "h6",
                          sm: "h5",
                          md: "h5",
                          lg: "h5",
                        },
                      }}
                    >
                      Ben Weintraub
                    </Typography>
                    <Box
                      backgroundColor="#191818"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        paddingX: 1,
                        borderRadius: 1,
                      }}
                    >
                      <Typography
                        color="#FFF1D5"
                        sx={{
                          typography: {
                            xs: "body2",
                            sm: "body2",
                            md: "body2",
                            lg: "body2",
                          },
                        }}
                      >
                        Co-founder
                      </Typography>
                    </Box>
                  </Stack>
                  <Typography
                    color="#F2F2F7"
                    sx={{
                      typography: {
                        xs: "body2",
                        sm: "body2",
                        md: "body2",
                        lg: "body2",
                      },
                    }}
                  >
                    Ben is a monetary and cryptoeconomic theorist and seasoned
                    entrepreneur. He is leading cryptoeconomic theory, strategy
                    and execution.
                  </Typography>
                </Stack>
                <Typography
                  color="#F2F2F7"
                  sx={{
                    typography: {
                      xs: "body2",
                      sm: "body2",
                      md: "body2",
                      lg: "body2",
                    },
                  }}
                >
                  Before Nebula, Ben cofounded Beanstalk, Basin, Pipeline (DeFi
                  protocols with &gt;$600m cumulative volume). Prior to building
                  DeFi protocols, Ben was the founder and CEO of South Side
                  Digital Capital, a crypto-focused hedge fund and RIA, from
                  2017-2019.
                </Typography>
                <Typography
                  color="#F2F2F7"
                  sx={{
                    typography: {
                      xs: "body2",
                      sm: "body2",
                      md: "body2",
                      lg: "body2",
                    },
                  }}
                >
                  Ben dropped out of the University of Chicago Computer Science
                  program.
                </Typography>
              </Stack>
            </Stack>
            <Divider color="#191818" />
            <Stack
              sx={{
                flexDirection: {
                  xs: "column",
                  sm: "column",
                  md: "row",
                  lg: "row",
                },
              }}
            >
              <Box
                component="img"
                sx={{
                  width: { xs: "72px", lg: "96px" },
                  height: { xs: "72px", lg: "96px" },
                  flexShrink: 0, // Prevents shrinking
                  borderRadius: 1.5,
                  border: "4px solid",
                  borderColor: "#191818",
                  marginRight: 2,
                  marginBottom: 2,
                  "@media print": {
                    border: "none !important", // Hide particles background when printing
                  },
                }}
                alt="brendan"
                src="brendan.png"
              />
              <Stack spacing={2}>
                <Stack spacing={0.5}>
                  <Stack direction="row" spacing={2}>
                    <Typography
                      color="white"
                      sx={{
                        typography: {
                          xs: "h6",
                          sm: "h5",
                          md: "h5",
                          lg: "h5",
                        },
                      }}
                    >
                      Brendan Sanderson
                    </Typography>
                    <Box
                      backgroundColor="#191818"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        paddingX: 1,
                        borderRadius: 1,
                      }}
                    >
                      <Typography
                        color="#FFF1D5"
                        sx={{
                          typography: {
                            xs: "body2",
                            sm: "body2",
                            md: "body2",
                            lg: "body2",
                          },
                        }}
                      >
                        Co-founder
                      </Typography>
                    </Box>
                  </Stack>
                  <Typography
                    color="#F2F2F7"
                    sx={{
                      typography: {
                        xs: "body2",
                        sm: "body2",
                        md: "body2",
                        lg: "body2",
                      },
                    }}
                  >
                    Brendan is a world class protocol designer, architect and
                    developer with a specialty in distributed systems. He is
                    leading design, architecture and development of the full
                    Nebula stack.
                  </Typography>
                </Stack>
                <Typography
                  color="#F2F2F7"
                  sx={{
                    typography: {
                      xs: "body2",
                      sm: "body2",
                      md: "body2",
                      lg: "body2",
                    },
                  }}
                >
                  Before Nebula, Brendan was the co-founder of Beanstalk,
                  Pipeline, Basin (DeFi protocols with &gt;$600m cumulative
                  volume). Prior to building DeFi protocols, Brendan worked at
                  Goldman Sachs as a software engineer from 2020-2021.
                </Typography>
                <Typography
                  color="#F2F2F7"
                  sx={{
                    typography: {
                      xs: "body2",
                      sm: "body2",
                      md: "body2",
                      lg: "body2",
                    },
                  }}
                >
                  Brendan holds a BS in Economics and BA in Computer Science
                  from the University of Chicago.
                </Typography>
              </Stack>
            </Stack>
            <Divider color="#191818" />
            <Stack
              sx={{
                flexDirection: {
                  sm: "column",
                  md: "row",
                },
              }}
            >
              <Box
                component="img"
                sx={{
                  width: { xs: "72px", lg: "96px" },
                  height: { xs: "72px", lg: "96px" },
                  flexShrink: 0, // Prevents shrinking
                  borderRadius: 1.5,
                  border: "4px solid",
                  borderColor: "#191818",
                  marginRight: 2,
                  marginBottom: 2,
                  "@media print": {
                    border: "none !important", // Hide particles background when printing
                  },
                }}
                alt="parsa"
                src="parsa.png"
              />
              <Stack spacing={2}>
                <Stack spacing={0.5}>
                  <Stack
                    gap={{ xs: 1, sm: 2 }}
                    sx={{
                      flexDirection: { xs: "column", sm: "row" },
                    }}
                  >
                    <Typography
                      color="white"
                      sx={{
                        typography: {
                          xs: "h6",
                          sm: "h5",
                          md: "h5",
                          lg: "h5",
                        },
                      }}
                    >
                      Parsa Shoa
                    </Typography>
                    <Box
                      backgroundColor="#191818"
                      sx={{
                        width: "fit-content",
                        display: "flex",
                        alignItems: "center",
                        paddingX: 1,
                        borderRadius: 1,
                      }}
                    >
                      <Typography
                        color="#FFF1D5"
                        sx={{
                          typography: {
                            xs: "body2",
                            sm: "body2",
                            md: "body2",
                            lg: "body2",
                          },
                        }}
                      >
                        Head of Exchange Development
                      </Typography>
                    </Box>
                  </Stack>
                  <Typography
                    color="#F2F2F7"
                    variant="body2"
                    pt={{ xs: 1, sm: 0 }}
                  >
                    Parsa is an expert in market microstructure, derivative
                    theory, data engineering and systems engineering. He is
                    leading design, architecture and development of the
                    exchange.
                  </Typography>
                </Stack>
                <Typography
                  color="#F2F2F7"
                  sx={{
                    typography: {
                      xs: "body2",
                      sm: "body2",
                      md: "body2",
                      lg: "body2",
                    },
                  }}
                >
                  Before Nebula, Parsa worked for 2+ years as a Quantitative
                  Developer for Blackrock and SpiderRock Advisors.
                </Typography>
                <Typography
                  color="#F2F2F7"
                  sx={{
                    typography: {
                      xs: "body2",
                      sm: "body2",
                      md: "body2",
                      lg: "body2",
                    },
                  }}
                >
                  Parsa holds an MSE in Industrial Operations Engineering and a
                  BSE in Computer Science Engineering from the University of
                  Michigan.
                </Typography>
              </Stack>
            </Stack>
            <Divider color="#191818" />
            <Stack
              sx={{
                flexDirection: {
                  xs: "column",
                  sm: "column",
                  md: "row",
                  lg: "row",
                },
              }}
            >
              <Box
                component="img"
                sx={{
                  width: { xs: "72px", lg: "96px" },
                  height: { xs: "72px", lg: "96px" },
                  flexShrink: 0, // Prevents shrinking
                  borderRadius: 1.5,
                  border: "4px solid",
                  borderColor: "#191818",
                  marginRight: 2,
                  marginBottom: 2,
                  "@media print": {
                    border: "none !important", // Hide particles background when printing
                  },
                }}
                alt="gus"
                src="gus.png"
              />
              <Stack spacing={2}>
                <Stack spacing={0.5}>
                  <Stack direction="row" spacing={2}>
                    <Typography
                      color="white"
                      sx={{
                        typography: {
                          xs: "h6",
                          sm: "h5",
                          md: "h5",
                          lg: "h5",
                        },
                      }}
                    >
                      Gus Schmidt
                    </Typography>
                    <Box
                      backgroundColor="#191818"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        paddingX: 1,
                        borderRadius: 1,
                      }}
                    >
                      <Typography
                        color="#FFF1D5"
                        sx={{
                          typography: {
                            xs: "body2",
                            sm: "body2",
                            md: "body2",
                            lg: "body2",
                          },
                        }}
                      >
                        Head of Cryptography
                      </Typography>
                    </Box>
                  </Stack>
                  <Typography
                    color="#F2F2F7"
                    sx={{
                      typography: {
                        xs: "body2",
                        sm: "body2",
                        md: "body2",
                        lg: "body2",
                      },
                    }}
                  >
                    Gus is a number theorist with a technical expertise in
                    blockchains. He is selecting, adapting and building novel
                    cryptographic solutions to create cryptographically secure
                    non-custodial applications.
                  </Typography>
                </Stack>
                <Typography
                  color="#F2F2F7"
                  sx={{
                    typography: {
                      xs: "body2",
                      sm: "body2",
                      md: "body2",
                      lg: "body2",
                    },
                  }}
                >
                  Gus is a Ph.D. candidate at Boston College where he studies
                  number theory and arithmetic geometry. Prior to studying for
                  his Ph.D., he received a M.Sc. in Mathematics from Brandeis
                  University in 2023 and a bachelor's degree in Mathematics from
                  the University of Chicago in 2020.
                </Typography>
                <Typography
                  color="#F2F2F7"
                  sx={{
                    typography: {
                      xs: "body2",
                      sm: "body2",
                      md: "body2",
                      lg: "body2",
                    },
                  }}
                >
                  While an undergraduate, he worked as Head of Research and
                  Quantitative Analytics at South Side Digital Capital, a
                  crypto-focused hedge fund and RIA.
                </Typography>
              </Stack>
            </Stack>
            <Divider color="#191818" />
            <Stack
              sx={{
                flexDirection: {
                  xs: "column",
                  sm: "column",
                  md: "row",
                  lg: "row",
                },
              }}
            >
              <Box
                component="img"
                sx={{
                  width: { xs: "72px", lg: "96px" },
                  height: { xs: "72px", lg: "96px" },
                  flexShrink: 0, // Prevents shrinking
                  borderRadius: 1.5,
                  border: "4px solid",
                  borderColor: "#191818",
                  marginRight: 2,
                  marginBottom: 2,
                  "@media print": {
                    border: "none !important", // Hide particles background when printing
                  },
                }}
                alt="austin"
                src="austin.png"
              />
              <Stack spacing={2}>
                <Stack spacing={0.5}>
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    gap={{ xs: 1, sm: 2 }}
                  >
                    <Typography color="white" variant={{ xs: "h6", md: "h5" }}>
                      Austin Moninger
                    </Typography>
                    <Box
                      backgroundColor="#191818"
                      sx={{
                        width: "fit-content",
                        display: "flex",
                        alignItems: "center",
                        paddingX: 1,
                        borderRadius: 1,
                      }}
                    >
                      <Typography
                        color="#FFF1D5"
                        variant="body2"
                        sx={{
                          whiteSpace: "nowrap",
                        }}
                      >
                        Chief Operating Officer
                      </Typography>
                    </Box>
                  </Stack>
                  <Typography
                    color="#F2F2F7"
                    variant="body2"
                    pt={{ xs: 1, sm: 0 }}
                  >
                    Austin's background in engineering and technical sales
                    enables him to tackle anything the business demands—product
                    development, back office or otherwise. He ensures everyone
                    at the company is rowing in the same direction as
                    efficiently as possible.
                  </Typography>
                </Stack>
                <Typography
                  color="#F2F2F7"
                  sx={{
                    typography: {
                      xs: "body2",
                      sm: "body2",
                      md: "body2",
                      lg: "body2",
                    },
                  }}
                >
                  Before Nebula, Austin spent 2+ years contributing to
                  Beanstalk, Pipeline, Basin (DeFi protocols with &gt;$600m
                  cumulative volume) as a leader of a distributed development
                  organization. Prior to working on DeFi protocols, he was the
                  first technical sales hire at Retool.
                </Typography>
                <Typography
                  color="#F2F2F7"
                  sx={{
                    typography: {
                      xs: "body2",
                      sm: "body2",
                      md: "body2",
                      lg: "body2",
                    },
                  }}
                >
                  Austin holds a BA in Computer Science from Rice University.
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};

export default TeamBio;
