import React from "react";
import { Box, Typography, Stack, Button } from "@mui/material";
import Navbar from "../components/layout/Navbar";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import Spline from "@splinetool/react-spline";

const OFS = () => {
  const navigate = useNavigate(); // Initialize useNavigate

  const handleNavigationHome = () => {
    navigate("/"); // Navigate to the "/" route
  };

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "100dvh", // Full height of viewport
        overflow: "auto",
        backgroundColor: "black", // Set background color to black for the whole page
      }}
    >
      {/* Navbar */}
      <Navbar
        backgroundColor={"black"}
        sx={{
          position: "sticky",
          top: 0,
          left: 0,
          width: "100%",
          boxShadow: "none",
          zIndex: 2, // Ensure the navbar stays above the particles
        }}
      />

      <Stack>
        {/* Top section where particles should be visible */}
        <Box
          sx={{
            height: "40vh",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            alignItems: "center",
            borderBottom: "1px solid",
            borderColor: "#272727",
            backgroundColor: "transparent", // Transparent so particles show
            zIndex: 1,
            "@media print": {
              borderBottom: "none", // Remove borders for print
              backgroundColor: "transparent !important", // Ensure no background color for printing
              height: "auto",
            },
          }}
        >
          {/* Particles Background Scoped to 40vh Box */}
          <Box
            sx={{
              position: "absolute",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
              zIndex: -1, // Particles stay behind the content
              "@media print": {
                display: "none !important", // Hide particles background when printing
                height: "auto",
              },
            }}
          >
            <Spline
              scene="https://prod.spline.design/AR2OsVSryukNaSwZ/scene.splinecode"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
            />
          </Box>

          <Stack
            spacing={1}
            sx={{
              width: "100%",
              maxWidth: "776px",
              alignItems: "start",
              paddingLeft: "24px",
              paddingRight: "24px",
              paddingBottom: { xs: "24px", sm: "24px", md: "24px", lg: "24px" },
              zIndex: 2, // Ensure text is above the particles
              "@media print": {
                height: "auto",
              },
            }}
          >
            <Button
              onClick={handleNavigationHome}
              variant="text"
              startIcon={
                <ArrowBack
                  sx={{
                    fontSize: "16px",
                    "&:hover": {
                      color: "#FFF1D5", // Text color when hovered
                      textShadow:
                        "0 0 1px #FFF1D5, 0 0 2px #FFF1D5, 0 0 8px #FFF1D5", // Glowing effect
                    },
                  }}
                />
              }
              sx={{
                fontFamily: "Electrolize, Arial, sans-serif",
                color: "#DCDCDC",
                fontSize: { xs: "16px", sm: "20px", md: "20px", lg: "20px" },
                fontWeight: "500",
                "@media (hover: hover)": {
                  "&:hover": {
                    color: "#FFF1D5", // Text color when hovered
                    textShadow:
                      "0 0 1px #FFF1D5, 0 0 2px #FFF1D5, 0 0 8px #FFF1D5", // Glowing effect
                  },
                },
              }}
            >
              Home
            </Button>
            <Typography
              gutterBottom
              color="white"
              sx={{
                typography: { xs: "h5", sm: "h4", md: "h4", lg: "h4" },
              }}
            >
              Concise Technical Document for Kyle
            </Typography>
            <Typography
              sx={{
                fontFamily: "Electrolize, Arial, sans-serif",
                color: "#DCDCDC",
                fontSize: { xs: "16px", sm: "20px", md: "20px", lg: "20px" },
                fontWeight: "500",
                "@media print": {
                  fontSize: "14px !important",
                },
              }}
            >
              6 min read
            </Typography>
          </Stack>
        </Box>

        {/* Main content section with solid black background */}
        <Box>
          <Stack
            spacing={4}
            sx={{
              maxWidth: "776px",
              margin: "0 auto",
              padding: 3,
              paddingY: { xs: "24px", sm: "24px", md: "36px", lg: "36px" },
              backgroundColor: "black", // Solid black for the main content area
              zIndex: 1, // Content is above particles, but no particles here
            }}
          >
            <Typography
              color="white"
              sx={{
                typography: {
                  xs: "body2",
                  sm: "body2",
                  md: "body2",
                  lg: "body2",
                },
              }}
            >
              Nebula is synthesizing a novel (1) token framework, (2) encryption
              scheme, (3) clearing mechanism and (4) market design to create the
              most capital efficient and flexible exchange on the market without
              requiring custody of users’ funds.
            </Typography>
            {/* Max Par H2 */}
            <Stack spacing={1}>
              <Typography
                color="white"
                sx={{
                  fontFamily: "Electrolize, Arial, sans-serif",
                  fontSize: { xs: "16px", sm: "20px", md: "20px", lg: "20px" },
                  fontWeight: "600",
                }}
              >
                nTokens
              </Typography>

              <Typography
                color="white"
                sx={{
                  typography: {
                    xs: "body2",
                    sm: "body2",
                    md: "body2",
                    lg: "body2",
                  },
                  "& li": {
                    marginLeft: "-24px",
                  },
                  "& ol": {
                    listStylePosition: "inside",
                    marginLeft: "0px",
                  },
                  "& ol li::marker": {
                    fontWeight: "bold",
                  },
                }}
              >
                The truest expression of an asset’s value is the complete series
                of sets of acceptable conditions under which it can be
                exchanged. nTokens allow an arbitrary number of sets of
                acceptable conditions for exchanging a token to be
                simultaneously and modularly attached to it. Nebula clears
                counterparties based on overlapping sets of acceptable
                conditions. The implications of this architecture are profound.
                <ol>
                  <li>
                    <strong>n x 1 Liquidity:</strong> Liquidity is not
                    fragmented between protocols or liquidity pools. An owner of
                    1 SOL that wants to trade their SOL for USDC can
                    simultaneously provide liquidity to SOL:USDC, JITOSOL:USDC
                    and mSOL:USDC pairs by attaching conditional approvals to
                    stake the SOL with Jito and Marinade if the net result of
                    the transaction is the receipt of at least some minimum
                    number of USDC. The depth of all 3 liquidity pools increases
                    by 1 SOL. The set of counterparties for the SOL owner to
                    trade with increases from exclusively owners of USDC that
                    want SOL to owners of USDC that want SOL, JITOSOL or mSOL.
                    <br /> <br />
                  </li>
                  <li>
                    <strong>n x m Liquidity:</strong> An offer to buy one asset
                    need not be exclusive of an offer to buy another asset. An
                    owner of SOL can provide liquidity to SOL:BONK, JITOSOL:BONK
                    and mSOL:BONK at the same time they provide liquidity to
                    SOL:USDC, JITOSOL:USDC and mSOL:USDC. By allowing the
                    provisioning of liquidity to multiple assets simultaneously,
                    Nebula eliminates significant opportunity cost to create the
                    deepest liquidity for long-tail assets.
                    <br /> <br />
                  </li>
                  <li>
                    <strong>n x m Lending:</strong> Any holder of value can
                    offer to lend out their value (1) in any form (<i>e.g.</i>,
                    SOL OR BONK), (2) for any set of terms (<i>e.g.</i>, fixed
                    or variable interest rate, term or callable loan) and (3)
                    against any acceptable set of collateral, simultaneously.
                    Lenders can now create offers to lend that can be matched
                    with any borrower that is willing to post any acceptable set
                    of collateral under any acceptable set of lending terms. By
                    allowing lenders to offer multiple loan terms
                    simultaneously, Nebula creates a lending environment
                    competitive with tradFi.
                    <br /> <br />
                  </li>
                  <li>
                    <strong>n x m Leverage:</strong> A liquidation need only
                    occur when there are no valid paths from A to B above the
                    liquidation threshold. For example, if someone borrows BTC
                    against JITOSOL, even if there is insufficient liquidity for
                    the BTC:JITOSOL pair to keep the position open, it can
                    remain open as long as there is ANY set of valid paths from
                    BTC to JITOSOL that can collectively cover the liability (
                    <i>e.g.</i>, BTC swap into SOL then stake into JITOSOL AND
                    BTC swap into JITOSOL). This dramatically improves the
                    amount of leverage that users can safely take on.
                    <br /> <br />
                  </li>
                  <li>
                    <strong>n x m Liquidations:</strong> This framework
                    naturally supports iso, cross and unified margin accounts
                    for all value on Nebula. Users can arbitrarily mix and match
                    types of assets as collateral and liabilities (<i>e.g.</i>,
                    spot, derivatives). An account remains solvent as long as
                    there is any set of paths from the collateral to the
                    liabilities it holds.
                    <br /> <br />
                  </li>
                  <li>
                    <strong>Frictionless Innovation:</strong> Nebula’s emphasis
                    on modular design maximizes the amount of code that is
                    reusable. Support for spot, perps, options, traditional
                    futures, swaps etc. can be implemented once and applied to
                    every asset. Because there is no opportunity cost to adding
                    a set of terms to an offer to trade, lend or borrow, there
                    is minimal friction for new developments to attract
                    significant liquidity. With dramatically less friction to
                    innovate and have innovations adopted, the pace of
                    innovation of financial derivatives on Nebula will
                    dramatically outpace other exchanges.
                  </li>
                </ol>
              </Typography>
            </Stack>
            {/* Exchange H2 */}
            <Stack spacing={1}>
              <Typography
                color="white"
                sx={{
                  fontFamily: "Electrolize, Arial, sans-serif",
                  fontSize: { xs: "16px", sm: "20px", md: "20px", lg: "20px" },
                  fontWeight: "600",
                }}
              >
                FHE
              </Typography>
              <Typography
                color="white"
                sx={{
                  typography: {
                    xs: "body2",
                    sm: "body2",
                    md: "body2",
                    lg: "body2",
                  },
                }}
              >
                Nebula is using FHE to keep liquidation thresholds, trade
                histories, FBA orders and trading strategies confidential.
                <br /> <br />
                As long as liquidation thresholds are public, non-custodial
                exchanges will be unable to protect on-chain leveraged positions
                from being hunted and thus never compete with CEXs. By using FHE
                to keep liquidation thresholds private, Nebula will be the first
                exchange to ever prove positions are not hunted.
                <br /> <br />
                Non-custodial exchanges currently impose significant costs on
                users by leaking their trading histories. By only decrypting the
                aggregate clearing volume and price within a batch, Nebula keeps
                accounts’ trading histories confidential and therefore removes
                this cost entirely.
                <br /> <br />
                FHE supports order confidentiality up to and through clearance.
              </Typography>
            </Stack>
            <Stack spacing={1}>
              <Typography
                color="white"
                sx={{
                  fontFamily: "Electrolize, Arial, sans-serif",
                  fontSize: { xs: "16px", sm: "20px", md: "20px", lg: "20px" },
                  fontWeight: "600",
                }}
              >
                FBA
              </Typography>
              <Typography
                color="white"
                sx={{
                  typography: {
                    xs: "body2",
                    sm: "body2",
                    md: "body2",
                    lg: "body2",
                  },
                }}
              >
                With FBA, MMs compete to offer takers the best price, not the
                first fill (<i>i.e.</i>, latency). FBA creates tighter spreads
                and enables better fills than serial clearing.
                <br /> <br />
                Matching based on latency imposes significant costs on makers,
                takers and overall market efficiency. The costs of the latency
                game are passed onto takers in the form of wider spreads.
                Furthermore, takers get filled by the resting liquidity or
                fastest MM (<i>i.e.</i>, the worst price), not the MM willing to
                offer the best price.
                <br /> <br />
                Batch order clearance at discrete intervals dramatically reduces
                the costs of latency asymmetry on makers and takers to create a
                significantly more efficient market. Lower barriers to entry and
                a level playing field increase competition between MMs which
                creates tighter spreads. The potential for frontrunning and
                backrunning attacks is removed because takers all clear at the
                best price within a batch.
                <br /> <br />
              </Typography>
            </Stack>
            <Stack spacing={1}>
              <Typography
                color="white"
                sx={{
                  fontFamily: "Electrolize, Arial, sans-serif",
                  fontSize: { xs: "16px", sm: "20px", md: "20px", lg: "20px" },
                  fontWeight: "600",
                }}
              >
                On-Chain Strategies
              </Typography>
              <Typography
                color="white"
                sx={{
                  typography: {
                    xs: "body2",
                    sm: "body2",
                    md: "body2",
                    lg: "body2",
                  },
                }}
              >
                In the near future, the market will be dominated by a large
                number of autonomous bots programmed to reflect their owner’s
                tastes, preferences and risk tolerances trading against one
                another. While professionals already have autonomous (albeit,
                expensive) execution, Nebula will empower individuals to easily
                encode their tastes, preferences and risk tolerances into a
                strategy and upload it to the exchange for provably fair
                autonomous execution. Widespread access to quality autonomous
                execution will create dramatically larger amounts of non-toxic
                volume.
                <br /> <br />
                The workload required to implement on-chain strategies is
                minimal on our architecture. While not necessary for launch, as
                mentioned, from conversations with professional traders on both
                sides of the market it seems there is a lot of demand for this
                feature because it eliminates the need to participate in the
                latency game and execution risk.
                <br /> <br />
                To be perfectly clear, while the idea for on-chain strategies
                emerged from our inquiry into removing MM’s exposure to toxic
                flow, we are solving toxic flow with FBA.
                <br /> <br />
                You're the man. Thank you for your time.
              </Typography>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};

export default OFS;
