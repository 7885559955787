// src/components/Auth/AuthProvider.js

import React, { createContext, useState } from "react";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [userEmail, setUserEmail] = useState(
    localStorage.getItem("user_email")
  );

  const login = (email) => {
    localStorage.setItem("user_email", email);
    setUserEmail(email);
  };

  const logout = () => {
    localStorage.removeItem("user_email");
    setUserEmail(null);
  };

  return (
    <AuthContext.Provider value={{ userEmail, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
