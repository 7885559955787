import React from "react";
import { Box, Typography, Stack, Button } from "@mui/material";
import Navbar from "../components/layout/Navbar";
import { ArrowBack, ArrowOutward } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import Spline from "@splinetool/react-spline";
import katex from "katex";
import "katex/dist/katex.min.css";

const LEAK = () => {
  const navigate = useNavigate(); // Initialize useNavigate

  const handleNavigationHome = () => {
    navigate("/"); // Navigate to the "/" route
  };

  // const handleNavigationFHE = () => {
  //   navigate("/FHE");
  // };

  // const handleNavigationDEX = () => {
  //   navigate("/DEX"); // Navigate to the DEX page
  // };

  // const handleContact = () => {
  //   window.open("https://www.t.me/paxamericana", "_blank"); // Opens the link in a new tab
  // };

  const probabilityString = katex.renderToString("(< 2^{-128})", {
    throwOnError: false,
  });

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "100dvh", // Full height of viewport
        overflow: "auto",
        backgroundColor: "black", // Set background color to black for the whole page
      }}
    >
      {/* Navbar */}
      <Navbar
        backgroundColor={"black"}
        sx={{
          position: "sticky",
          top: 0,
          left: 0,
          width: "100%",
          boxShadow: "none",
          zIndex: 2, // Ensure the navbar stays above the particles
        }}
      />

      <Stack>
        {/* Top section where particles should be visible */}
        <Box
          sx={{
            height: "40vh",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            alignItems: "center",
            borderBottom: "1px solid",
            borderColor: "#272727",
            backgroundColor: "transparent", // Transparent so particles show
            zIndex: 1,
            "@media print": {
              borderBottom: "none", // Remove borders for print
              backgroundColor: "transparent !important", // Ensure no background color for printing
              height: "auto",
            },
          }}
        >
          {/* Particles Background Scoped to 40vh Box */}
          <Box
            sx={{
              position: "absolute",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
              zIndex: -1, // Particles stay behind the content
              "@media print": {
                display: "none !important", // Hide particles background when printing
              },
            }}
          >
            <Spline
              scene="https://prod.spline.design/AR2OsVSryukNaSwZ/scene.splinecode"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
            />
          </Box>

          <Stack
            spacing={1}
            sx={{
              width: "100%",
              maxWidth: "776px",
              alignItems: "start",
              paddingLeft: "24px",
              paddingRight: "24px",
              paddingBottom: { xs: "24px", sm: "24px", md: "24px", lg: "24px" },
              zIndex: 2, // Ensure text is above the particles
              "@media print": {
                height: "auto",
              },
            }}
          >
            <Button
              onClick={handleNavigationHome}
              variant="text"
              startIcon={
                <ArrowBack
                  sx={{
                    fontSize: "16px",
                    "&:hover": {
                      color: "#FFF1D5", // Text color when hovered
                      textShadow:
                        "0 0 1px #FFF1D5, 0 0 2px #FFF1D5, 0 0 8px #FFF1D5", // Glowing effect
                    },
                  }}
                />
              }
              sx={{
                fontFamily: "Electrolize, Arial, sans-serif",
                color: "#DCDCDC",
                fontSize: { xs: "16px", sm: "20px", md: "20px", lg: "20px" },
                fontWeight: "500",
                "@media (hover: hover)": {
                  "&:hover": {
                    color: "#FFF1D5", // Text color when hovered
                    textShadow:
                      "0 0 1px #FFF1D5, 0 0 2px #FFF1D5, 0 0 8px #FFF1D5", // Glowing effect
                  },
                },
              }}
            >
              Home
            </Button>
            <Typography
              gutterBottom
              color="white"
              sx={{
                typography: { xs: "h5", sm: "h4", md: "h4", lg: "h4" },
              }}
            >
              Strategy Leakage and Competitive Performance
            </Typography>
            <Typography
              sx={{
                fontFamily: "Electrolize, Arial, sans-serif",
                color: "#DCDCDC",
                fontSize: { xs: "16px", sm: "20px", md: "20px", lg: "20px" },
                fontWeight: "500",
                "@media print": {
                  fontSize: "14px !important",
                },
              }}
            >
              8 min read
            </Typography>
          </Stack>
        </Box>

        {/* Main content section with solid black background */}
        <Box>
          <Stack
            spacing={4}
            sx={{
              maxWidth: "776px",
              margin: "0 auto",
              padding: 3,
              paddingY: { xs: "24px", sm: "24px", md: "36px", lg: "36px" },
              backgroundColor: "black", // Solid black for the main content area
              zIndex: 1, // Content is above particles, but no particles here
            }}
          >
            {/* Strategy Leakage H1 */}
            <Stack spacing={1}>
              <Typography
                color="white"
                sx={{
                  fontFamily: "Electrolize, Arial, sans-serif",
                  fontSize: { xs: "22px", sm: "32px", md: "32px", lg: "32px" },
                  fontWeight: "600",
                }}
              >
                Strategy Leakage
              </Typography>
              <Typography
                color="white"
                sx={{
                  typography: {
                    xs: "body2",
                    sm: "body1",
                    md: "body1",
                    lg: "body1",
                  },
                }}
              >
                Having MM strategies derived through any means is a major risk
                we must mitigate in order to secure Nebula exchange. Strategy
                information may leak through exploiting aspects of the
                underlying cryptography (FHE, functional encryption (FE) and
                indistinguishability obfuscation (iO)) or exchange protocols.
              </Typography>
            </Stack>
            {/* Cryptography H2 */}
            <Typography
              color="white"
              sx={{
                fontFamily: "Electrolize, Arial, sans-serif",
                fontSize: { xs: "20px", sm: "28px", md: "28px", lg: "28px" },
                fontWeight: "600",
              }}
            >
              Cryptography
            </Typography>
            {/* FHE H3 */}
            <Stack spacing={1}>
              <Typography
                color="white"
                sx={{
                  fontFamily: "Electrolize, Arial, sans-serif",
                  fontSize: { xs: "18px", sm: "22px", md: "22px", lg: "22px" },
                  fontWeight: "700",
                }}
              >
                FHE
              </Typography>

              <Typography
                color="white"
                sx={{
                  typography: {
                    xs: "body2",
                    sm: "body1",
                    md: "body1",
                    lg: "body1",
                  },
                }}
              >
                All existing FHE schemes are susceptible to secret key leakage
                via side-channel and direct key-recovery attacks if not
                carefully implemented.
                <br /> <br />
                <strong>Side-channel attacks: </strong> The only operations
                where information about the FHE secret key may be leaked via a
                side-channel are the
                <span className="responsive-KaTeX"> Setup</span> and
                <span className="responsive-KaTeX"> Decrypt</span> functions
                because those are the only two functions that involve it.
                <br /> <br />
                <span className="responsive-KaTeX">Setup</span> happens once
                through a trusted process. A properly performed trusted{" "}
                <span className="responsive-KaTeX">Setup</span> should
                probabilistically guarantee there was no leakage (<i>e.g.</i>,
                ETH KZG ceremony).
                <br /> <br />
                For <span className="responsive-KaTeX">Decrypt</span>, we are
                focused on preventing side-channels across all hardware that
                could potentially run our software. A defense-in-depth approach
                that combines state of the art mitigation techniques is best
                suited for this attack vector.
                <br /> <br />
                First, <span className="responsive-KaTeX">Decrypt</span> will
                use constant-time functions to normalize the amount of time and
                energy it takes to run. Second, we will implement various
                noise-adding techniques that limit the amount of usable
                information that gets leaked per decryption. We will also use a
                state of the art framework to detect and mitigate leakages
                across different hardware architectures.
                <br /> <br />
                <strong>Direct key-recovery attacks: </strong> We can set the
                parameters of the FHE scheme to achieve a negligible{" "}
                <span
                  dangerouslySetInnerHTML={{ __html: probabilityString }}
                ></span>{" "}
                probability of decryption failure, which probabilistically
                eliminates the risk of an attack. Based on our benchmarks, doing
                so will increase the cost of computation by only ~50% compared
                to default parameters for less adversarial environments.
              </Typography>
            </Stack>
            {/* FE H3 */}
            <Stack spacing={1}>
              <Typography
                color="white"
                sx={{
                  fontFamily: "Electrolize, Arial, sans-serif",
                  fontSize: { xs: "18px", sm: "22px", md: "22px", lg: "22px" },
                  fontWeight: "700",
                }}
              >
                FE
              </Typography>

              <Typography
                color="white"
                sx={{
                  typography: {
                    xs: "body2",
                    sm: "body1",
                    md: "body1",
                    lg: "body1",
                  },
                }}
              >
                FE schemes have a different — but similarly critical — master
                secret key which is used to generate functional secret keys
                unique to users. Therefore, FE schemes are similarly vulnerable
                to side-channel attacks that exploit leakage from{" "}
                <span className="responsive-KaTeX">Setup</span> and
                <span className="responsive-KaTeX"> Decrypt</span> and key
                recovery attacks that exploit leakage from{" "}
                <span className="responsive-KaTeX">Decrypt</span>. Our
                strategies for mitigating leakage of the FHE secret key are also
                applicable to FE <span className="responsive-KaTeX">Setup</span>{" "}
                and <span className="responsive-KaTeX">Decrypt</span>. Due to
                the novelty of FE and its composition with FHE, we will perform
                a robust universal composability analysis to determine if
                supplementary measures must be implemented.
                <br /> <br />
                However, FE schemes also carry the risk of side-channel attacks
                on the <span className="responsive-KaTeX">KeyGen</span> program
                that generates functional secret keys. We are also taking a
                defense-in-depth approach to mitigate this attack vector. First,
                we are obfuscating the master secret key and the entire{" "}
                <span className="responsive-KaTeX">KeyGen</span> program using
                iO (see below for more info on iO security). Second,{" "}
                <span className="responsive-KaTeX">KeyGen</span> requires random
                noise as an input. In practice, this means that even in the
                instance where the master secret key is leaked, the attacker
                would not be able to decrypt another user’s strategy without
                also replicating the noise used to generate the strategy’s
                decryption key. Furthermore, because the obfuscated{" "}
                <span className="responsive-KaTeX">KeyGen</span> is expensive to
                run, creating a data set of sufficient size to derive
                information about the master secret key is cost and time
                prohibitive given the limited utility of the master secret key,
                without a way to replicate the noise used for some particular{" "}
                <span className="responsive-KaTeX">KeyGen</span> function call.
              </Typography>
            </Stack>
            {/* iO H3 */}
            <Stack spacing={1}>
              <Typography
                color="white"
                sx={{
                  fontFamily: "Electrolize, Arial, sans-serif",
                  fontSize: { xs: "18px", sm: "22px", md: "22px", lg: "22px" },
                  fontWeight: "700",
                }}
              >
                iO
              </Typography>

              <Typography
                color="white"
                sx={{
                  typography: {
                    xs: "body2",
                    sm: "body1",
                    md: "body1",
                    lg: "body1",
                  },
                }}
              >
                In addition to learning the secret keys by leaking information
                from <span className="responsive-KaTeX">Setup</span>,{" "}
                <span className="responsive-KaTeX">Decrypt</span> or{" "}
                <span className="responsive-KaTeX">KeyGen</span>, both the FHE
                secret key and FE master secret key are vulnerable to discovery
                if the iO implementation used to obfuscate the conditional
                decryption scheme that houses them is broken.
                <br /> <br />
                Such an attack would require generating a large dataset of many
                obfuscated programs to learn sufficient information about the
                obfuscation program that an attacker may use to derive a
                non-obfuscated program from its obfuscation. Due to the
                significant cost of generating even a single obfuscated program,
                such an attack is highly impractical.
                <br /> <br />
                However, we are building the first ever practical implementation
                of iO. With this in mind, we are actively working to discover
                and prevent potential unknown attack vectors.
                <br /> <br />
              </Typography>
            </Stack>
            {/* Key Rotation H3 */}
            <Stack spacing={1}>
              <Typography
                color="white"
                sx={{
                  fontFamily: "Electrolize, Arial, sans-serif",
                  fontSize: { xs: "18px", sm: "22px", md: "22px", lg: "22px" },
                  fontWeight: "700",
                }}
              >
                Key Rotation
              </Typography>

              <Typography
                color="white"
                sx={{
                  typography: {
                    xs: "body2",
                    sm: "body1",
                    md: "body1",
                    lg: "body1",
                  },
                }}
              >
                In practice, it is impossible to leak zero information from a
                cryptosystem. However, if (1) a key is rotated regularly and (2)
                the cryptosystem leaks so little information about the key
                before it is rotated that it is computationally infeasible to
                derive it from the leaked information, we can probabilistically
                guarantee security.
                <br /> <br />
                Key rotation is similar for the FHE secret key and FE master
                secret key, but very different for the functional secret keys.
                In practice, functional secret key rotation is by far the
                simplest to perform. Our security model relies on frequent
                rotation of functional secret keys and programmatic enforcement
                that only the newest functional secret keys can be used to
                decrypt. In practice this allows MMs to rotate keys as
                frequently as they desire to ensure the security of their
                strategies.
                <br /> <br />
                While it is possible to rotate the FHE secret key and FE master
                secret key by obfuscating a new{" "}
                <span className="responsive-KaTeX">KeyGen</span> program housing
                a new set of keys, previous obfuscations of{" "}
                <span className="responsive-KaTeX">KeyGen</span> containing
                earlier versions of the secret keys will remain public. Even in
                the instance where the FE master secret key is leaked, the noise
                used in FE <span className="responsive-KaTeX">KeyGen</span>{" "}
                prevents the replication of individual functional secret keys.
                However, once an FHE secret key is leaked, any information
                encrypted under that key may be decrypted.
                <br /> <br />
                Therefore, contingent upon the success of our other attack
                mitigations, the security of a strategy is ultimately dependent
                on the the security of the{" "}
                <span className="responsive-KaTeX">KeyGen</span> obfuscation. In
                practice, we estimate it would take between 10-100 years to
                crack our iO implementation at a significant cost.
                <br /> <br />
                Ultimately, our proposition to MMs is that their strategy has
                very strong probabilistic security guarantees for 10-100 years
                and will not be linked to their account in the instance it is
                derived. The MMs we have been consulting throughout our design
                process have agreed this is an acceptable security model.
              </Typography>
            </Stack>
            {/* Exchange H2 */}
            <Stack spacing={1}>
              <Typography
                color="white"
                sx={{
                  fontFamily: "Electrolize, Arial, sans-serif",
                  fontSize: { xs: "20px", sm: "28px", md: "28px", lg: "28px" },
                  fontWeight: "600",
                }}
              >
                Exchange
              </Typography>
              <Typography
                color="white"
                sx={{
                  typography: {
                    xs: "body2",
                    sm: "body1",
                    md: "body1",
                    lg: "body1",
                  },
                }}
              >
                There is also risk of the strategy leaking via back-solving
                using legitimate historical inputs or simulation attacks using
                illegitimate inputs.
                <br /> <br />
                <strong>Back-solving:</strong> Malicious actors may derive
                strategies over time by evaluating the historical mapping from
                legitimate inputs into the strategy to decrypted outputs. By
                allowing MMs to obfuscate strategy inputs with a bitmask and
                modify its size, the difficulty of determining a strategy from
                its inputs and outputs can be tuned to their preference.
                <br /> <br />
                <strong>Simulation attacks:</strong> Strategies may also be
                reverse engineered by manipulating a local fork of the execution
                environment to generate a large set of mappings from inputs to
                outputs. Such attacks can be prevented by only allowing the FE
                to decrypt the orderbook after verifying the inputs to the
                strategies that generated it were canonical oracle inputs.
                <br /> <br />
                This verification can be done without imposing additional
                liveness assumptions on strategy owners by allowing them to
                delegate the signing of the canonical oracle inputs to an MPC
                protocol that consists of signers that are disincentivized from
                colluding via a 'suicide pact'. For example, if multiple market
                makers' signatures are required to run execution, they cannot
                collude to derive another’s strategy without also leaking their
                own strategy.
                <br /> <br />
                Preventing MM strategy leakage is an intricate and ever-changing
                problem worthy of much further discussion.
                <br /> <br />
              </Typography>
            </Stack>
            {/* Competitive Performance H1*/}
            <Stack spacing={1}>
              <Typography
                color="white"
                sx={{
                  fontFamily: "Electrolize, Arial, sans-serif",
                  fontSize: { xs: "22px", sm: "32px", md: "32px", lg: "32px" },
                  fontWeight: "600",
                }}
              >
                Competitive Performance
              </Typography>
              <Typography
                color="white"
                sx={{
                  typography: {
                    xs: "body2",
                    sm: "body1",
                    md: "body1",
                    lg: "body1",
                  },
                }}
              >
                A common thread in our comments on strategy leakage is that
                attacks are generally preventable at the cost of increased
                compute. Therefore, we are highly focused on maximizing
                performance. <br /> <br />
                We are creating a user experience with competitive latency and
                throughput to existing CEXs. Given the performance of modern FHE
                schemes, doing so requires optimization at every level. Our
                approach combines maximal parallelization and composability to
                minimize the amount of computation that is running in serial and
                the time serial computation takes to run.
              </Typography>
            </Stack>
            {/* Maximal Parallelization H2 */}
            <Stack spacing={1}>
              <Typography
                color="white"
                sx={{
                  fontFamily: "Electrolize, Arial, sans-serif",
                  fontSize: { xs: "20px", sm: "28px", md: "28px", lg: "28px" },
                  fontWeight: "600",
                }}
              >
                Maximal Parallelization
              </Typography>
              <Typography
                color="white"
                sx={{
                  typography: {
                    xs: "body2",
                    sm: "body1",
                    md: "body1",
                    lg: "body1",
                  },
                }}
              >
                Nebula is designed to run as much computation in parallel both
                within a single machine and across multiple machines.
                <br /> <br />
                Within each market, (1) market maker strategies and liquidation
                checks are run entirely in parallel, (2) order creation and
                deletion are parallelized across different price ticks and (3)
                order fulfillment gets batched using a frequent batch auction.
                Therefore, the number of strategies, positions and trades
                possible in a given market scales with the computational
                resources allocated to it.
                <br /> <br />
                Because generating strategies from orders are by far the
                lengthiest calculations, the actual latency of the exchange will
                be slightly more than the the time to run the most complex
                strategy. By imposing limitations on strategy complexity, we can
                guarantee low latency despite FHE slowdowns.
                <br /> <br />
                Nebula can also maximize performance by fragmenting execution
                between unrelated markets to run them in parallel on separate
                machines such that each market can utilize 100% of the
                computation resources of the best machine available. Doing so
                allows for arbitrary scaling at the cost of capital efficiency.
                <br /> <br />
                In practice, this means performance needs can be met through
                tradeoffs along market maker strategy complexity and capital
                efficiency. As the quality of FHE improves, the need to make
                these tradeoffs will decrease. Collectively, these optimizations
                should allow the exchange to scale the number of makers and
                takers across markets with minimal effect on user experience
                from day 1.
              </Typography>
            </Stack>
            {/* FHE Speedups H2 */}
            <Stack spacing={1}>
              <Typography
                color="white"
                sx={{
                  fontFamily: "Electrolize, Arial, sans-serif",
                  fontSize: { xs: "20px", sm: "28px", md: "28px", lg: "28px" },
                  fontWeight: "600",
                }}
              >
                FHE Speedups
              </Typography>
              <Typography
                color="white"
                sx={{
                  typography: {
                    xs: "body2",
                    sm: "body1",
                    md: "body1",
                    lg: "body1",
                  },
                }}
              >
                Given the arbitrarily large number of markets we can support via
                parallelization, we estimate we can create a UX that is highly
                competitive with CEXs if FHE speed is less than ~1,000x slower
                than plaintext computation.
                <br /> <br />
                Benchmarks of current FHE libraries show they are 1,000,000x
                slower than their plaintext equivalents. We have spoken with
                many of the companies focused on FHE performance. Some have
                suggested that within a year, the cost of FHE computation will
                approach plaintext computation, but we are not counting on this.
                We anticipate a 100-1,000x speedup from hardware improvements
                and 10-100x speedups from software improvements within the next
                two years. This will decrease the overhead of FHE to 10-1000x
                and provide ample speedup for us to launch a highly competitive
                product.
                <br /> <br />
                We are building everything to be FHE scheme agnostic and will
                also be able to combine FHE schemes to use the most performant
                scheme for each particular set of operations. Therefore, Nebula
                is poised to take advantage of any and all performance gains as
                they become available.
              </Typography>
            </Stack>
            {/* Action buttons */}
            {/* <Stack
              direction={{ xs: "column", sm: "row" }}
              sx={{ width: { xs: "100%", sm: "none" } }}
              spacing={2}
            >
              <Stack direction="row" spacing={2}>
                <Stack sx={{ width: { xs: "100%", sm: "auto" } }}>
                  <Button
                    onClick={handleNavigationFHE}
                    variant="contained"
                    sx={{
                      fontFamily: "Electrolize, Arial, sans-serif",
                      fontSize: {
                        xs: "16px",
                        sm: "20px",
                        md: "20px",
                        lg: "20px",
                      },
                      height: {
                        xs: "44px",
                        sm: "52px",
                        md: "52px",
                        lg: "52px",
                      },
                      color: "white",
                      backgroundColor: "#1B1B1B",
                      border: "1px solid",
                      borderColor: "#494949",
                      "&:hover": {
                        backgroundColor: "rgba(27, 27, 27, 0.6)", // Change opacity on hover
                        color: "rgba(255, 255, 255, 0.8)", // Change text color opacity on hover
                      },
                    }}
                  >
                    read about FHE
                  </Button>
                </Stack>
                <Stack sx={{ width: { xs: "100%", sm: "auto" } }}>
                  <Button
                    onClick={handleNavigationDEX}
                    variant="contained"
                    sx={{
                      fontFamily: "Electrolize, Arial, sans-serif",
                      fontSize: {
                        xs: "16px",
                        sm: "20px",
                        md: "20px",
                        lg: "20px",
                      },
                      height: {
                        xs: "44px",
                        sm: "52px",
                        md: "52px",
                        lg: "52px",
                      },
                      color: "white",
                      backgroundColor: "#1B1B1B",
                      border: "1px solid",
                      borderColor: "#494949",
                      "&:hover": {
                        backgroundColor: "rgba(27, 27, 27, 0.6)", // Change opacity on hover
                        color: "rgba(255, 255, 255, 0.8)", // Change text color opacity on hover
                      },
                    }}
                  >
                    read about DEXs
                  </Button>
                </Stack>
              </Stack>
              <Button
                onClick={handleContact}
                variant="contained"
                endIcon={<ArrowOutward />}
                sx={{
                  fontFamily: "Electrolize, Arial, sans-serif",
                  fontSize: { xs: "16px", sm: "20px", md: "20px", lg: "20px" },
                  height: { xs: "44px", sm: "52px", md: "52px", lg: "52px" },
                  color: "white",
                  backgroundColor: "black",
                  border: "1px solid",
                  borderColor: "#494949",
                  "&:hover": {
                    backgroundColor: "black",
                    color: "rgba(255, 255, 255, 0.8)", // Change text color opacity on hover
                    borderColor: "rgba(73, 73, 73, 0.8)", // Change border color opacity on hover
                  },
                }}
              >
                contact us
              </Button>
            </Stack> */}
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};

export default LEAK;
