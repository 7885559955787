import React, { useState, useEffect } from "react";
import Navbar from "../components/layout/Navbar";
import Spline from "@splinetool/react-spline";
import { Box, Typography, Link, Fade } from "@mui/material";
import { Stack } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

const HomePage = () => {
  const [contentVisible, setContentVisible] = useState(false); // For both text and Spline visibility
  const theme = useTheme();
  const isSmallScreen = window.innerWidth <= 600;

  // Trigger the fade-in for both the Spline and the text content
  useEffect(() => {
    setContentVisible(true); // Show both Spline and text at the same time
  }, []);

  return (
    <>
      <Box
        sx={{
          background: "#000000",
          position: "absolute",
          width: "100%",
          height: "100dvh",
          overflow: "hidden",
          margin: 0,
          padding: 0,
        }}
      >
        {/* Navbar */}
        <Navbar
          backgroundColor={"transparent"}
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            boxShadow: "none",
          }}
        />

        <Spline
          scene="https://prod.spline.design/AR2OsVSryukNaSwZ/scene.splinecode"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
        />

        {/* Main content that appears after Spline */}
        <Fade in={contentVisible} timeout={1000}>
          <Box
            sx={{
              position: "absolute",
              top: "20%",
              left: 0,
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Stack direction="row" spacing={{ xs: 4, sm: 4, md: 12, lg: 12 }}>
              {/* First Link (FHE) */}
              <Typography
                color="white"
                sx={{ typography: { xs: "h2", sm: "h2", md: "h1", lg: "h1" } }}
              >
                <Link
                  component={RouterLink}
                  to="/FHE"
                  color="inherit"
                  underline="none"
                  className="pulse-animation-fhe" // Apply the class here
                  sx={{
                    "&:hover": {
                      color: "#FFF1D5",
                    },
                    display: "flex",
                  }}
                >
                  FHE
                </Link>
              </Typography>

              {/* Second Link (DEX) */}
              <Typography
                color="white"
                sx={{ typography: { xs: "h2", sm: "h2", md: "h1", lg: "h1" } }}
              >
                <Link
                  component={RouterLink}
                  to="/DEX"
                  color="inherit"
                  underline="none"
                  className="pulse-animation-dex" // Apply the class here
                  sx={{
                    "&:hover": {
                      color: "#FFF1D5",
                    },
                    display: "flex",
                  }}
                >
                  DEX
                </Link>
              </Typography>
            </Stack>
          </Box>
        </Fade>

        {/* Contact link in bottom-right corner */}
        <Fade in={contentVisible} timeout={1250}>
          <Typography
            variant="h6"
            sx={{
              position: "absolute",
              bottom: 24,
              right: 24,
              "& a": {
                color: "#FFFFFF",
                textDecoration: "none",
                "@media (hover: hover)": {
                  "&:hover": {
                    color: "#FFF1D5", // Text color when hovered
                    textShadow:
                      "0 0 1px #FFF1D5, 0 0 2px #FFF1D5, 0 0 4px #FFF1D5", // Glowing effect
                  },
                },
              },
            }}
          >
            <a
              href="https://t.me/paxamericana"
              target="_blank"
              rel="noopener noreferrer"
            >
              contact us
            </a>
          </Typography>
        </Fade>
      </Box>
    </>
  );
};

export default HomePage;
