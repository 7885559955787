import React from "react";
import { Box, Typography, Stack, Button } from "@mui/material";
import Navbar from "../components/layout/Navbar";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import Spline from "@splinetool/react-spline";

const CompetitivePerformance = () => {
  const navigate = useNavigate(); // Initialize useNavigate

  const handleNavigationHome = () => {
    navigate("/"); // Navigate to the "/" route
  };

  // const handleNavigationFHE = () => {
  //   navigate("/FHE");
  // };

  // const handleNavigationDEX = () => {
  //   navigate("/DEX"); // Navigate to the DEX page
  // };

  // const handleContact = () => {
  //   window.open("https://www.t.me/paxamericana", "_blank"); // Opens the link in a new tab
  // };

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "100dvh", // Full height of viewport
        overflow: "auto",
        backgroundColor: "black", // Set background color to black for the whole page
      }}
    >
      {/* Navbar */}
      <Navbar
        backgroundColor={"black"}
        sx={{
          position: "sticky",
          top: 0,
          left: 0,
          width: "100%",
          boxShadow: "none",
          zIndex: 2, // Ensure the navbar stays above the particles
        }}
      />

      <Stack>
        {/* Top section where particles should be visible */}
        <Box
          sx={{
            height: "40vh",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            alignItems: "center",
            borderBottom: "1px solid",
            borderColor: "#272727",
            backgroundColor: "transparent", // Transparent so particles show
            zIndex: 1,
            "@media print": {
              borderBottom: "none", // Remove borders for print
              backgroundColor: "transparent !important", // Ensure no background color for printing
              height: "auto",
            },
          }}
        >
          {/* Particles Background Scoped to 40vh Box */}
          <Box
            sx={{
              position: "absolute",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
              zIndex: -1, // Particles stay behind the content
              "@media print": {
                display: "none !important", // Hide particles background when printing
              },
            }}
          >
            <Spline
              scene="https://prod.spline.design/AR2OsVSryukNaSwZ/scene.splinecode"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
            />
          </Box>

          <Stack
            spacing={1}
            sx={{
              width: "100%",
              maxWidth: "776px",
              alignItems: "start",
              paddingLeft: "24px",
              paddingRight: "24px",
              paddingBottom: { xs: "24px", sm: "24px", md: "24px", lg: "24px" },
              zIndex: 2, // Ensure text is above the particles
              "@media print": {
                height: "auto",
              },
            }}
          >
            <Button
              onClick={handleNavigationHome}
              variant="text"
              startIcon={
                <ArrowBack
                  sx={{
                    fontSize: "16px",
                    "&:hover": {
                      color: "#FFF1D5", // Text color when hovered
                      textShadow:
                        "0 0 1px #FFF1D5, 0 0 2px #FFF1D5, 0 0 8px #FFF1D5", // Glowing effect
                    },
                  }}
                />
              }
              sx={{
                fontFamily: "Electrolize, Arial, sans-serif",
                color: "#DCDCDC",
                fontSize: { xs: "16px", sm: "20px", md: "20px", lg: "20px" },
                fontWeight: "500",
                "@media (hover: hover)": {
                  "&:hover": {
                    color: "#FFF1D5", // Text color when hovered
                    textShadow:
                      "0 0 1px #FFF1D5, 0 0 2px #FFF1D5, 0 0 8px #FFF1D5", // Glowing effect
                  },
                },
              }}
            >
              Home
            </Button>
            <Typography
              gutterBottom
              color="white"
              sx={{
                typography: { xs: "h5", sm: "h4", md: "h4", lg: "h4" },
              }}
            >
              Competitive Performance
            </Typography>
            <Typography
              sx={{
                fontFamily: "Electrolize, Arial, sans-serif",
                color: "#DCDCDC",
                fontSize: { xs: "16px", sm: "20px", md: "20px", lg: "20px" },
                fontWeight: "500",
                "@media print": {
                  fontSize: "14px !important",
                },
              }}
            >
              2 min read
            </Typography>
          </Stack>
        </Box>

        {/* Main content section with solid black background */}
        <Box>
          <Stack
            spacing={4}
            sx={{
              maxWidth: "776px",
              margin: "0 auto",
              padding: 3,
              paddingY: { xs: "24px", sm: "24px", md: "36px", lg: "36px" },
              backgroundColor: "black", // Solid black for the main content area
              zIndex: 1, // Content is above particles, but no particles here
            }}
          >
            <Typography
              color="white"
              sx={{
                typography: {
                  xs: "body2",
                  sm: "body1",
                  md: "body1",
                  lg: "body1",
                },
              }}
            >
              We are creating a user experience with competitive latency and
              throughput to existing CEXs. Given the performance of modern FHE
              schemes, doing so requires optimization at every level. Our
              approach combines maximal parallelization and composability to
              minimize the amount of computation that is running in serial and
              the time serial computation takes to run.
            </Typography>
            {/* Max Par H2 */}
            <Stack spacing={1}>
              <Typography
                color="white"
                sx={{
                  fontFamily: "Electrolize, Arial, sans-serif",
                  fontSize: { xs: "20px", sm: "28px", md: "28px", lg: "28px" },
                  fontWeight: "600",
                }}
              >
                Maximal Parallelization
              </Typography>

              <Typography
                color="white"
                sx={{
                  typography: {
                    xs: "body2",
                    sm: "body1",
                    md: "body1",
                    lg: "body1",
                  },
                }}
              >
                Nebula is designed to run as much computation in parallel both
                within a single machine and across multiple machines.
                <br /> <br />
                Within each market, (1) market maker strategies and liquidation
                checks are run entirely in parallel, (2) order creation and
                deletion are parallelized across different price ticks and (3)
                order fulfillment gets batched using a frequent batch auction.
                Therefore, the number of strategies, positions and trades
                possible in a given market scales with the computational
                resources allocated to it.
                <br /> <br />
                Because generating strategies from orders are by far the
                lengthiest calculations, the actual latency of the exchange will
                be slightly more than the the time to run the most complex
                strategy. By imposing limitations on strategy complexity, we can
                guarantee low latency despite FHE slowdowns.
                <br /> <br />
                Nebula can also maximize performance by fragmenting execution
                between unrelated markets to run them in parallel on separate
                machines such that each market can utilize 100% of the
                computation resources of the best machine available. Doing so
                allows for arbitrary scaling at the cost of capital efficiency.
                <br /> <br />
                In practice, this means performance needs can be met through
                tradeoffs along market maker strategy complexity and capital
                efficiency. As the quality of FHE improves, the need to make
                these tradeoffs will decrease. Collectively, these optimizations
                should allow the exchange to scale the number of makers and
                takers across markets with minimal effect on user experience
                from day 1.
              </Typography>
            </Stack>
            {/* Exchange H2 */}
            <Stack spacing={1}>
              <Typography
                color="white"
                sx={{
                  fontFamily: "Electrolize, Arial, sans-serif",
                  fontSize: { xs: "20px", sm: "28px", md: "28px", lg: "28px" },
                  fontWeight: "600",
                }}
              >
                FHE Speedups
              </Typography>
              <Typography
                color="white"
                sx={{
                  typography: {
                    xs: "body2",
                    sm: "body1",
                    md: "body1",
                    lg: "body1",
                  },
                }}
              >
                Given the arbitrarily large number of markets we can support via
                parallelization, we estimate we can create a UX that is highly
                competitive with CEXs if FHE speed is less than ~1,000x slower
                than plaintext computation.
                <br /> <br />
                Benchmarks of current FHE libraries show they are 1,000,000x
                slower than their plaintext equivalents. We have spoken with
                many of the companies focused on FHE performance. Some have
                suggested that within a year, the cost of FHE computation will
                approach plaintext computation, but we are not counting on this.
                We anticipate a 100-1,000x speedup from hardware improvements
                and 10-100x speedups from software improvements within the next
                two years. This will decrease the overhead of FHE to 10-1000x
                and provide ample speedup for us to launch a highly competitive
                product.
                <br /> <br />
                We are building everything to be FHE scheme agnostic and will
                also be able to combine FHE schemes to use the most performant
                scheme for each particular set of operations. Therefore, Nebula
                is poised to take advantage of any and all performance gains as
                they become available.
                <br /> <br />
              </Typography>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};

export default CompetitivePerformance;
