// src/theme/theme.js

import { createTheme } from "@mui/material/styles";
import "@fontsource/electrolize";
import "@fontsource/lora";

const theme = createTheme({
  // Customize your theme here
  typography: {
    fontFamily: "Electrolize, Arial, sans-serif", // Set Electrolize as the default font
    h1: {
      fontFamily: "Electrolize, Arial, sans-serif", // Optionally customize specific typography variants
      fontSize: 208,
    },
    h2: {
      fontFamily: "Electrolize, Arial, sans-serif",
      fontSize: 72,
      fontWeight: 600,
    },
    h4: {
      fontFamily: "Electrolize, Arial, sans-serif",
      fontSize: 32,
      fontWeight: 600,
    },
    h5: {
      fontFamily: "Electrolize, Arial, sans-serif",
      fontSize: 24,
      fontWeight: 700,
    },
    h6: {
      fontFamily: "Electrolize, Arial, sans-serif",
      fontSize: 20,
    },
    body1: {
      fontFamily: "Electrolize, Arial, sans-serif",
      fontSize: 20,
      lineHeight: 1.6,
    },
    body2: {
      fontFamily: "Electrolize, Arial, sans-serif",
      fontSize: 16,
      lineHeight: 1.6,
    },
  },
  palette: {
    primary: {
      main: "#1976d2", // Customize primary color
    },
    secondary: {
      main: "#dc004e", // Customize secondary color
    },
  },
  components: {
    // MuiBackdrop: {
    //   styleOverrides: {
    //     root: {
    //       opacity: 0.5,
    //     },
    //   },
    // },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: "24px",
        },
        sizeSmall: {
          fontSize: "16px",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          backgroundColor: "white",
          boxShadow: "none",
          borderRadius: "0px",
          height: "52px",
          fontSize: "20px",
          fontWeight: "500",
          textTransform: "none",
          border: "1px solid #3F3F3F",
          "&:hover": {
            backgroundColor: "#DCDCDC",
            boxShadow: "none", // Disable shadow on hover
          },
          "&:disabled": {
            backgroundColor: "#f0f0f0",
          },
          "&.Mui-disabled": {
            color: "#ccc", // Custom disabled text color
          },
        },
        text: {
          textTransform: "none",
          padding: "0px",
          minWidth: "unset",
          height: "34px",
          "&:hover": {
            backgroundColor: "black",
            boxShadow: "none",
            color: "#BCBCBC",
          },
        },
      },
      defaultProps: {
        disableElevation: true, // Disable elevation globally
        disableRipple: true, // Disable ripple globally
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: "#272727",
        },
        root: {
          "& .MuiOutlinedInput-input::placeholder": {
            color: "grey", // Change placeholder text color to grey
          },
          "& .MuiOutlinedInput-input": {
            color: "white", // Change input text color when filled to white
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#3F3F3F", // Change border color on hover
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#3F3F3F", // Border color when focused
          },
        },
      },
    },
  },
});

export default theme;
